<template>
  <MasterDetail
    refs="masterDetail"
    :actionBarButtons="actionBarButtons"
    asyncExportType="nfepresumido"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    formTitle="Nota Fiscal do Presumido"
    :hasExportCSV="false"
    :hasMonthlyFilter="true"
    :hasNewButton="false"
    :opts="opts"
    :resourceUrl="resourceUrl"
    :serverPagination="true"
  >
    <v-dialog
      v-model="classificarDialog"
      scrollable
      persistent
      max-width="500"
      @keydown="dialogKeyDown($event)"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-cog-sync</v-icon>
          Classificar Notas Fiscais
          <v-btn
            @click="classificarDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p><b> Selecione o período para classificar:</b></p>
          <v-menu
            v-model="dateMenu"
            :close-on-click="competenciaClassificacao.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaClassificacao | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaClassificacao"
              range
              type="month"
              @click:month="dateMenu = competenciaClassificacao.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="classificarDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="classificarPeriodo()"
          >
            Executar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(["clientId", "intervaloCompetencia"]),
    actionBarButtons() {
      return [{
        text: "Classificar Notas Fiscais",
        icon: "mdi-cog-sync",
        action: () => {
          this.classificarDialog = true;
        },
      }];
    },
    resourceUrl() {
      return `v1/faturamento/nfpresumido/${this.clientId}`;
    },
    cols() {
      return [
        {
          key: "status",
          name: "Status de Cálculo",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "port_inf_compl",
          name: "Port. Inf. Compl.",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "credito_presumido",
          name: "Classificação Créd. Presumido",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "data_emissao",
          name: "Data de Emissão",
          type: this.$fieldTypes.DATE,
          align: 1
        },
        {
          key: "num_doc",
          name: "Nº Doc",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "num_item",
          name: "Nº Item",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "codigo",
          name: "Cód. Item",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "descr_compl",
          name: "Desc. Complementar",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "cfop",
          name: "CFOP",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vl_item",
          name: "Valor Item",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "cst_icms",
          name: "CST ICMS",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "vl_bc_icms",
          name: "Valor BC ICMS",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "aliq_icms",
          name: "Aliq. ICMS",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "vl_icms_op",
          name: "Valor ICMS OP",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "p_dif",
          name: "P DIF",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vl_icms_dif",
          name: "Valor ICMS DIF",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "vl_icms",
          name: "Valor ICMS",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "vlr_oper",
          name: "Valor Oper.",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
        {
          key: "cred_pres",
          name: "Crédito Presumido",
          type: this.$fieldTypes.MONEY,
          align: 1
        },
      ];
    },
  },
  data() {
    const competenciaClassificacao = this.$store.getters.intervaloCompetencia;
    return {
      classificarDialog: false,
      competenciaClassificacao,
      dateMenu: false,
      opts: {},
    };
  },
  methods: {
    async classificarPeriodo() {
      const [ini, fim] = this.competenciaClassificacao;
      const query = `competenciaIni=${ini}&competenciaFim=${fim}`;
      const { save } = this.apiResource(
        `v1/faturamento/calculaPresumido/${this.clientId}?${query}`
      );
      await save();
      this.classificarDialog = false;
      this.$refs.masterDetail?.doLoad();
    },
    dialogKeyDown({ code }) {
      if (code === "Escape") {
        this.classificarDialog = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.table-v-action-button {
  display: inline-block;
}
</style>
